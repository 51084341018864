<template>
  <GMapMap
    ref="refMap"
    :center="center"
    :zoom="10"
    map-type-id="terrain"
    style="width: 100vw; height: 30rem"
    :styles="clusterIcon"

  >
    <GMapCluster :zoomOnClick="true">
      <GMapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="onMarkerClick(index)"
        :icon="m.icon"
        style="width: 100vw;"
      />
    </GMapCluster>
  </GMapMap>

  <button v-if="canCopy" @click="copy()">Copy</button>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'

export default defineComponent({
  setup (props) {
    const refMap = ref(null)

    const updateMapPosition = (map) => {
      const center = map.getCenter()
      console.log('map', map.getZoom(), center.lat(), center.lng())
    }

    onMounted(() => {
      refMap.value.$mapPromise.then((map) => {
        map.addListener('center_changed', () => {
          updateMapPosition(map)
        })
        map.addListener('zoom_changed', () => {
          updateMapPosition(map)
        })
      })
    })

    return {
      refMap
    }
  },
  data () {
    return {
      colorFromScriptA: 'red',
      colorFromScriptB: 'green',
      canCopy: false,
      center: { lat: 51.093048, lng: 6.84212 },
      markers: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212
          },
          icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png#A'
        },
        {
          position: {
            lat: 51.198429,
            lng: 6.69529
          },
          icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png#B'
        },
        {
          position: {
            lat: 51.165218,
            lng: 7.067116
          },
          icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png#A'
        },
        {
          position: {
            lat: 51.09256,
            lng: 6.84074
          },
          icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png#B'
        }
      ]
    }
  },
  created () {
    this.canCopy = !!navigator.clipboard
  },
  methods: {
    async copy () {
      await navigator.clipboard.writeText(window.location.href)
      alert('Copied!')
    },
    onMarkerClick (index) {
      alert('marker clicked ' + index)
    }
  }
})
</script>

<style>
body {
  margin: 0;
}
img[src='https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png#A']{
 border-radius: 100px;
 background-color: v-bind('colorFromScriptA')
}
img[src='https://developers.google.com/maps/documentation/javascript/examples/full/images/info-i_maps.png#B']{
 border-radius: 100px;
 background-color: v-bind('colorFromScriptB')
}
</style>
