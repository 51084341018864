<template>
<div class="container" v-if="categoryStore">
    <div class="row">
         <div class="col-xl-4 offset-xl-4 col-12">
          <div class="row mt-5 mb-3">
            <div class="col-10 offset-1 text-center">
              <p class="font15 fw-normal">İlgi alanın olan sosyal kategorileri işaretlemek ister misin?</p>
            </div>
          </div>
            <div class="row">
              <div class="col-6 mb-5 text-center" v-for="(item,index) in categoryStore.socialCategories" :key="item" v-on:click="onCatClicked(index)">
                <div class="h-100 w-100 rounded-4 mb-2 d-flex align-items-center justify-content-center cetagory" style="position:relative !important;" :style='{ background: item.bgColor }'>
                  <img :src="item.media.fullPath" class="img-fluid" alt="category Image" style="height:60px;width:auto !important;">
                  <div class="form-check category-checkbox">
                    <input class="form-check-input d-flex align-items-center justify-content-center rounded-circle" style="padding:10px !important;" type="checkbox" :value="item.id" :id="'cat'+index" v-model="categoryStore.selectedCategoryIds">
                  </div>
                </div>
                <p class="font14 fw-semibold"> {{ item.name }}</p>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 text-center position-relative">
              <button type="button" class="btn rounded-pill shadow-lg mb-2" style="background-color:white!important;height:56px !important;width:56px !important;"  @click="setSelectedCategoryIds">
                <svg style="fill: #D1434B!important;height:40px!important;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L274.7 256 105.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg>
             </button>
             <div>
              <p class="font15 m-0">Tamamla</p>
             </div>
             <router-link :to="{ name: 'first mission' }" class="font24" style="text-decoration:none !important;position:absolute;bottom:0;right:25px !important;">
          Daha sonra
        </router-link>
              </div>
            </div>
             <span class="d-none">ID: {{ categoryStore.selectedCategoryIds }}</span>
         </div>
    </div>
</div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useCategoryStore } from '../store/category'
import { useRouter } from 'vue-router'
import { useDirectionStore } from '../store/direction'

export default defineComponent({
  name: 'ProfileView',
  data () { return { } },
  setup () {
    history.pushState(null, null, location.href)
    window.onpopstate = function () {
      history.go(1)
    }
    const router = useRouter()
    const categoryStore = useCategoryStore()
    const directionStore = useDirectionStore()
    onMounted(() => {
      categoryStore.getCategoryList()
    })
    const setSelectedCategoryIds = () => {
      categoryStore.setSelectedCategoryIds().then(
        response => {
          console.log(response)
          if (response.status === 200) {
            directionStore.getUserDirectionList().then(
              response => {
                if (response.data.duties === false) {
                    router.push('/home-map')
                  } 
                  else 
                  {
                    // router.push('/first-mission')
                    router.push('/home-map')
                  }
              }
            )
          }
        }
      )
    }
    return {
      categoryStore,
      setSelectedCategoryIds,
      directionStore
    }
  },
  components: {
  },
  methods: {
    onCatClicked (index) {
      const category = document.getElementById('cat' + index)
      category.click()
    }
  }
})
</script>
<style scoped lang="scss">
@import 'src/styles/bootstrap_sass.scss';
@import 'src/styles/style.scss';
</style>
