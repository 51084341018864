<template>
  <div class="home">
    <div class="container position-relative" style="height: 100vh; display: flex; align-items: center; justify-content: center; width: max-content !important;">
      <div class="row">
        <div class="col-12">
          <router-link
            to="/login"
            class="d-flex align-items-center justify-content-center"
          >
            <div class="row">
              <div class="col-12">
                <img
                  alt="Coridor Logo Image"
                  src="../assets/landing-page-images/logo.webp"
                  class="img-fluid"
                  style="height: 65px"
                />
              </div>
            </div>
          </router-link>
          <div class="lds-roller d-flex justify-content-center mt-3"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <div class="row w-100 position-absolute bottom-0">
            <div class="col-12 text-center">
              <p class="baseFont">from Güçlü İyilik</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  setup () {
    onMounted(() => {
      setTimeout(() => {
        router.push('/login')
      }, 3000)
    })
    const router = useRouter()
  }
})
</script>
<style scoped lang="scss">
@import 'src/styles/bootstrap_sass.scss';
@import 'src/styles/style.scss';
.lds-roller {
  display: inline-block;
  position: relative;
  width: 140px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #d1434b !important;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
