import { BaseService } from './BaseService'
const CATEGORY_LIST_PATH = '/social-category'
const SELECT_CATEGORY_LIST_PATH = '/select-social-category'
class CategoryService extends BaseService {
  getCategoryList () {
    return this.get(CATEGORY_LIST_PATH)
  }

  setSelectedCategoryIds (formData) {
    return this.post(SELECT_CATEGORY_LIST_PATH, formData)
  }
}
export default new CategoryService()
