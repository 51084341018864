import { acceptHMRUpdate, defineStore } from 'pinia'
import profileService from '@/services/profile.service'

export const useProfileStore = defineStore({
  id: 'profileStore',
  state: () => ({
    profileSettings: null
  }),
  setup () {},
  actions: {
    getProfileList () {
      return profileService.getProfileList().then(
        response => {
          this.profileSettings = response.data
          return response
        }
      )
    },
    updateProfile (formData) {
      return profileService.updateProfile(formData)
    },
    deleteAccount () {
      return profileService.deleteAccount()
    },
    sendMessage (formData) {
      return profileService.sendMessage(formData)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProfileStore, import.meta.hot))
}
