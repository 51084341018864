import { BaseService } from './BaseService'
const PROFILE_LIST_PATH = '/users/my-profile'
const UPDATE_PROFILE_PATH = '/users/update-my-profile'
const DELETE_ACCOUNT_PATH = '/users/delete-account'
const PROFILE_CONTACT_PATH = '/contact'
/* eslint-disable */
class ProfileService extends BaseService {
  getProfileList () {
    return this.get(PROFILE_LIST_PATH)
  }
  updateProfile (formData) {
    return this.put(UPDATE_PROFILE_PATH, formData)
  }
  deleteAccount (why) {
    why ??= "";
    return this.get(DELETE_ACCOUNT_PATH+'?why='+why)
  }
  sendMessage (formData) {
    return this.post(PROFILE_CONTACT_PATH, formData)
  }
}
export default new ProfileService()
/* eslint-disable */ 
