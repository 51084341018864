import { BaseService } from './BaseService'
const GOOGLE_LOGIN_PATH = '/google/login'
const FACEBOOK_LOGIN_PATH = '/facebook/login'
/* eslint-disable */ 
class SocialLoginService extends BaseService {
  sendSocialLoginId (name, formData) {
    if (name === 'Google') {
      return this.post(GOOGLE_LOGIN_PATH, formData)
    } else if (name === 'Facebook') {
      return this.post(FACEBOOK_LOGIN_PATH, formData)
    }
  }
}
export default new SocialLoginService()
/* eslint-disable */ 
